<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }"></div>
</template>

<script>
// import tdTheme from './theme.json'
// import '../map/fujian'

export default {
  name: 'echart',
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '2.5rem'
    },
    options: {
      type: Object,
      default: ()=>({})
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    options: {
      // 更新数据
      handler (options) {
        // 设置true 清空echart缓存
        this.chart.setOption(options, true)
      },
      deep: true
    }
  },
  mounted () {
    // 在echarts实例中注册主题
    // this.$echarts.registerTheme('tdTheme', tdTheme)
    this.initChart()
  },
  beforeDestroy () {
    // 销毁实例，销毁后实例无法再被使用。
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      // 初始化echart，this.$el只在mounted中才有效 用于获取vue实例挂载的dom元素
      this.chart = this.$echarts.init(this.$el, 'tdTheme')
      // 设置true 清空echart缓存。更新配置
      this.chart.setOption(this.options, true)
    }
  }
}
</script>

<style>

</style>