<template>
  <div class="line-chart">
    <Echart :options="options" id="lineChart" height="310px" width="100%" />
  </div>
</template>

<script>
import Echart from '../../../common/echart'

export default {
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Echart
  },
  data() {
    return {
      options: {}
    }
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          // 提示框组件
          tooltip: {
            // 坐标轴触发
            trigger: "axis",
            backgroundColor: "rgba(255,255,255,0.1)",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                backgroundColor: "#7B7DDC"
              }
            }
          },
          xAxis: {
            show: true,
            type: 'category',
            name: '日期',
            data: newData.end_time,
            axisLabel: {
              show: true,
              rotate: 30,
              textStyle: {
                fontSize: 12
              }
            },
            axisTick: {
              show: true
            }
          },
          yAxis: {
            type: 'value',
            show: true,
            name: '积分',
            axisLabel: {
              show: true,
              formatter: '{value} 分',
              textStyle: {
                fontSize: 12
              }
            },
          },
          series: [
            {
              type: "line",
              smooth: true,
              symbol: 'emptyCircle',
              symbolSize: 10,
              lineStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#956FD4" },
                  { offset: 1, color: "#3EACE5" }
                ]),
                width: 2
              },
              data: newData.is_target
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>

</style>