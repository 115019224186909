<template>
  <div class="personal-file">
    <div class="chat-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/user.png`)" fit="contain"></el-image>
        <div class="head-title">个人中心</div>
      </div>
      <div class="head-right">
        <div class="prove-body">
          <div class="prove" @click="gotoProve(1)">
            <el-image class="img" :src="require(`../../../assets/image/school.png`)" fit="contain"></el-image>
            <span class="title">学校认证</span>
          </div>
          <div class="prove" @click="gotoProve(2)">
            <el-image class="img" :src="require(`../../../assets/image/enterprise.png`)" fit="contain"></el-image>
            <span class="title">公司认证</span>
          </div>
        </div>
        <el-image class="exit" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
      </div>
    </div>

    <div class="chat-center">
      <div class="chat-center-left">
        <el-image class="img" :src="userData.img" fit="contain"></el-image>
        <div class="left-title">
          <div class="name">{{ userData.name }}</div>
          <div class="class">{{ userData.gender }} / {{ userData.chain_name }} / {{ userData.cell_phone }}</div>
        </div>
      </div>
      <div class="chat-center-right" @click="gotoPersonal">编辑</div>
    </div>

    <div class="chat-main">
      <div class="result-target-body" v-for="item in classifyData" :key="item.student_questionnaire_classify_id" @click="gotoTargetScore(item.student_questionnaire_result_id)">
        <div class="body-title">{{ item.classify_name }}</div>
        <div class="body-echarts">
          <div class="echarts-title">
            <div class="title-left">
              <div>
                <div>总计</div>
                <div><span class="number">{{ subhead(item) * 10 }}</span>分</div>
              </div>
              <div>
                <div>目标<span class="number">{{ item.targetData.integralAll.length * 10 }}</span>分</div>
                <div>进度<span class="number">{{ item.targetData.integralAll.length > 0 ? Math.floor((subhead(item) / item.targetData.integralAll.length) * 100) : 0 }}%</span></div>
              </div>
            </div>
            <div class="title-right">
              <div class="right-label">
                <div class="label opt-label">周</div>
                <div class="label">月</div>
                <div class="label">年</div>
              </div>
              <div class="right-annotation">{{ item.targetData.annotation }}</div>
            </div>
          </div>
          <lineChart :cdata="item.targetData.cdata" />
        </div>
      </div>
      <!-- <div class="result-target-bottom">
        <div>底部</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import lineChart from '../components/lineChart.vue'
import { checkIntegral, getTargetIntegral, getAiUserDetails } from '../../../request/api'

export default {
  components: {
    lineChart
  },
  data() {
    return {
      classifyData: [],
      userData: {
        name: '',
        chain_name: '',
        gender: '',
        cell_phone: '',
        img: 'https://bmbshopping.oss-cn-hangzhou.aliyuncs.com/shopgoods/0.1372555556030275f52fcef3161f505f12aa10574f3ce35.png',
        type: ''
      }
    }
  },
  methods: {
    sendExit() {
      this.$emit('update-value')
    },
    subhead(item) {
      return item.targetData.integralAll.filter(obj => obj.is_target === 1).length
    },
    gotoPersonal() {
      this.$router.push('personal')
    },
    gotoProve(index) {
      switch(index) {
        case 1:
          if (this.userData.type === 2) {
            this.$message.warning('您已经通过企业认证')
          } else {
            this.$router.push('proveSchool')
          }
          break
        case 2:
          if (this.userData.type === 1) {
            this.$message.warning('您已经通过学校认证')
          } else {
            this.$router.push('proveEnterprise')
          }
          break
      }
    },
    gotoTargetScore(student_questionnaire_result_id) {
      this.$router.push(
        {
          path: '/questionResultTargetScore',
          query: {
            questionnaire_result_id: student_questionnaire_result_id
          }
        }
      )
    },
    async getTarget(student_questionnaire_result_id) {
      try {
        let retTargetData = {
          annotation: '',
          cdata: {
            end_time: [],
            is_target: []
          },
          integralAll: []
        }

        const res = await getTargetIntegral(student_questionnaire_result_id, 1)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
        }
        const targetData = res.data.data
        retTargetData.cdata.end_time = targetData.end_time
        retTargetData.cdata.is_target = targetData.is_target
        retTargetData.integralAll = targetData.resultTargets

        const begin_annotation = new Date(targetData.begin_annotation)
        const finish_annotation = new Date(targetData.finish_annotation)
        retTargetData.annotation = (`${begin_annotation.getFullYear()}年${('0' + (begin_annotation.getMonth() + 1)).slice(-2)}月${('0' + begin_annotation.getDate()).slice(-2)}日-${finish_annotation.getFullYear()}年${('0' + (finish_annotation.getMonth() + 1)).slice(-2)}月${('0' + finish_annotation.getDate()).slice(-2)}日`)

        return retTargetData
      } catch(err) {
        this.$message.error('获取数据出错了', err)
      }
    },
    async init() {
      try {
        const res = await checkIntegral()
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
        }
        const targetPromises = res.data.data.map(ele => {
          return this.getTarget(ele.student_questionnaire_result_id).then(targetData => {
            return { ...ele, targetData }
          })
        })

        this.classifyData = await Promise.all(targetPromises)
      } catch(err) {
        this.$message.error('获取数据出错了', err)
      }
    },
    async getAiUser(){
      try {
        const res = await getAiUserDetails()
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
        }

        const details = res.data.data
        this.userData.type = details.getAiChain[0].type
        this.userData.chain_name = details.getAiChain[0].chain_name
        this.userData.name = details.getAiUser ? details.getAiUser.name : ''
        this.userData.gender = details.getAiUser ? details.getAiUser.gender : ''
        this.userData.cell_phone = details.getAiUser ? details.getAiUser.cell_phone : ''
      } catch(err) {
        this.$message.error('获取数据出错了', err)
      }
    }
  },
  mounted() {
    this.init()
    this.getAiUser()
  }
}
</script>

<style lang="less" scoped>
.personal-file {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-center {
    background-color: #5699fe;
    background-image: url(../../../assets/image/personalFile.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    border-radius: 10px;
    min-height: 170px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .chat-center-left {
      display: flex;
      align-items: center;
      .img {
        margin-right: 20px;
        height: 120px;
      }
      .left-title {
        height: 58px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #fcfdfe;
        .name {
          font-size: 18px;
        }
      }
    }
    .chat-center-right {
      background-color: #dbeff6;
      height: 30px;
      width: 64px;
      line-height: 30px;
      text-align: center;
      border-radius: 6px;
      color: #4064ef;
      cursor: pointer;
      &:hover {
        background-color: #eaf4f8;
      }
    }
  }
  .chat-main {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .result-target-body {
      background-color: #fff;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .body-title {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: bold;
        color: #5699fe;
      }
      .body-echarts {
        display: flex;
        flex-direction: column;
        .echarts-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title-left {
            display: flex;
            width: 150px;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            .number {
              font-size: 18px;
              color: #5699fe;
              font-weight: bold;
            }
          }
          .title-right {
            display: flex;
            flex-direction: column;
            width: 180px;
            .right-label {
              display: flex;
              border: 1px solid #696969;
              justify-content: space-between;
              height: 38px;
              text-align: center;
              line-height: 38px;
              border-radius: 4px;
              font-weight: bold;
              margin-bottom: 4px;
              .opt-label {
                background-color: #5699fe;
                color: #fff;
              }
              .label {
                flex: 1;
                border-right: 1px solid #696969;
                &:last-child {
                  border-right: none;
                }
              }
            }
            .right-annotation {
              font-size: 10px;
              color: #7a7c7e;
              text-align: center;
            }
          }
        }
      }
    }
    .result-target-bottom {
      background-color: #fff;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
  .chat-head {
    min-height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      width: 300px;
      height: 100%;
      padding-right: 1%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .prove-body {
        display: flex;
        .prove {
          display: flex;
          align-items: center;
          margin-right: 20px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          .img {
            width: 30px;
          }
          .title {
            font-size: 14px;
            border-bottom: 1px solid #2a6ee9;
            color: #2a6ee9;
          }
        }
      }
      .exit {
        width: 70px;
        cursor: pointer;
      }
    }
  }
}
</style>